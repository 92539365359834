.wrapper {
  width: 100%;
  max-width: 800px;
  margin: 35vh auto;
  text-align: center;

  p {
    color: wheat;
  }

  h1 {
    color: orangered;
    margin-bottom: 2rem;
  }

  button {
    cursor: pointer;
    border-radius: 10px;
    color: wheat;
    padding: 0.2rem;
    background-color: orangered;
    font-size: larger;
    margin-top: 2rem;
  }
}
