.tableWrapper {
  margin: 0px auto;
  border: 2px solid rgb(181, 211, 175);
  border-radius: 20px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;

  table {
    border: 0;
    width: 100%;
    // Heitght set to 1px bc it is not possible to stretch flexbox in td to 100% height without it.
    height: 1px;
    border-collapse: collapse;
    text-align: center;

    thead {
      tr:nth-child(1) {
        background-color: rgb(127, 185, 230);
      }
    }

    tbody {
      tr:nth-child(2n) {
        background-color: rgb(255, 195, 168);
      }

      tr:nth-child(2n -1) {
        background-color: rgb(255, 211, 168);
      }

      td {
        font-weight: 600;
      }

      td:nth-last-child(1) div {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        button {
          padding: 0.25rem;
          border: none;
          border-radius: 15%;
          cursor: pointer;
          color: wheat;
          margin: 0.25rem;
          min-width: 3rem;
        }

        button:hover {
          filter: brightness(120%);
        }

        button:nth-child(1) {
          background-color: darkgreen;
        }
        button:nth-child(2) {
          background-color: orangered;
        }
        button:only-child {
          background-color: red;
        }
      }
    }
  }
}
