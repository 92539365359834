.header {
  position: fixed;
  width: 250px;
  height: 100%;
  background-color: rgb(0, 31, 46);

  ul {
    display: flex;
    flex-direction: column;
    height: 100%;

    li {
      list-style: none;
      width: 100%;
      border-top: 1px solid rgb(255, 255, 255, 0.1);
      border-bottom: 1px solid black;
      transition: 0.5s;

      &:hover a {
        margin-left: 50px;
      }

      a {
        text-decoration: none;
        text-transform: uppercase;
        display: block;
        line-height: 65px;
        font-size: 24px;
        color: whitesmoke;
        margin-left: 35px;
        transition: 0.4s;
      }

      a.active {
        margin-left: 50px;
        color: black;
      }
    }

    li:has(a.active) {
      background-color: rgb(245, 239, 239);
    }
  }

  &.open {
    background-color: blue;
  }
}
