.shishasDatePicker {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  input,
  select {
    padding: 5px;
    margin: 5px;
    text-align: center;
    outline: none;
    border: none;
    border-radius: 50px;
    -webkit-box-shadow: -1px 8px 25px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px 8px 25px -4px rgba(0, 0, 0, 0.75);
    box-shadow: -1px 8px 25px -4px rgba(0, 0, 0, 0.75);
  }

  select,
  option {
    cursor: pointer;
  }

  h2 {
    text-align: center;
  }

  .inputError {
    border: 2px solid red !important;
  }

  .error {
    margin: 0.5rem auto;
    font-size: 1rem;
    color: red;
    border: 2px dotted red;
    padding: 0 1rem;
  }

  .datePickerWeapper {
    margin-left: 3%;
    width: 200px;
    display: flex;
  }
}
