.map {
  width: 100%;
  height: 100%;

  img {
    object-fit: cover;
    width: 100%;
    min-height: 100%;
    transition: 0.8s;

    &:hover {
      scale: 110%;
    }
  }
}
