@use "../../../globalStyles" as *;

.newOrderFormWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 1rem;
  border-bottom: 2px dashed orangered;
  border-radius: 100px;

  input[type="checkbox"] {
    margin: 0 auto;
    cursor: pointer;
  }

  .formWrapper {
    // background-color: rgb(245, 239, 239);
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 1200px;
    margin: 0px auto 20px;
    padding: 19.5px;
    border: 2px solid rgba(0, 128, 0, 0);
    border-radius: 10px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    text-align: center;

    form {
      font-size: x-large;
      margin: 20px auto;
      display: flex;
      flex-direction: column;

      label {
        margin: 0 auto;
        font-weight: bold;
      }

      label > a {
        color: orangered;
        text-decoration: underline;
      }

      input[type="text"],
      select,
      textarea {
        font-family: "Kalam", sans-serif;
        padding: 5px 5px 5px 15px;
        width: 70%;
        margin: 0px auto 1.5rem;
        font-size: large;
        outline: none;
        border: 1px solid gainsboro;
        border-radius: 5px;
        &:focus {
          outline: 2px solid rgb(255, 195, 168);
        }
      }

      input.inputError {
        margin-bottom: 0;
      }

      button {
        margin: 0 auto;
        padding: 10px;
        width: 30%;
        transition: 0.3s ease;
        border: 1px solid rgb(184, 183, 183);
        border-radius: 50px;
        font-size: large;
        &:hover,
        &:focus {
          color: aliceblue;
          background-color: rgb(20, 101, 39);
          cursor: pointer;
        }
      }
    }

    span {
      color: orangered;
    }

    .agreements {
      display: flex;
      flex-direction: column;
      width: 70%;
      margin: 0 auto;

      div {
        text-align: start;

        label {
          font-size: medium;
          margin-left: 1rem;
          cursor: pointer;
        }
      }
    }
  }

  label {
    color: wheat;
  }

  h1,
  h4 {
    color: orangered;
  }

  .inputError {
    border: 2px solid red !important;
  }

  .error {
    margin: 0.5rem auto;
    font-size: 1rem;
    color: red;
    border: 2px dotted red;
    padding: 0 1rem;
  }
}

.wrapper {
  grid-column: 1/3;

  label:not([for="userNote"]):not([for="rememberMe"])::after {
    content: " *";
    color: red;
  }
}

@media (max-width: $mobile-width) {
  .newOrderFormWrapper {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;

    .formWrapper {
      margin-bottom: 0;

      form {
        margin-bottom: 0;

        input[type="text"],
        select,
        textarea {
          width: 90%;
        }

        button {
          width: 70%;
        }

        .agreements {
          width: 90%;
        }
      }
    }
  }
}
