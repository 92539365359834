.listOfShisha {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-grow: 1;
  margin: 30px auto;
  width: 100%;
  max-width: 1200px;

  li {
    list-style: none;
  }
}
