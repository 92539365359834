.formWrapper {
  background-color: rgb(245, 239, 239);
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 1200px;
  margin: 0px auto 20px;
  padding: 19.5px;
  border: 2px solid rgba(0, 128, 0, 0);
  border-radius: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  text-align: center;

  h1 {
    text-align: center;
    text-decoration: underline dotted rgba(21, 180, 21, 0.727);
    text-underline-offset: 10px;
    line-height: 50px;
  }

  form {
    font-size: x-large;
    margin: 20px auto;
    display: flex;
    flex-direction: column;

    fieldset {
      padding: 15px;
    }

    label {
      margin: 0 auto;
      font-weight: bold;
    }

    input[type="text"],
    select,
    textarea {
      font-family: cursive;
      padding: 5px 5px 5px 15px;
      width: 70%;
      margin: 0px auto 15px;
      font-size: large;
      outline: none;
      border: 1px solid gainsboro;
      border-radius: 5px;
      &:focus {
        outline: 2px solid rgb(255, 195, 168);
      }
    }

    input[type="file"] {
      margin: 5px auto 15px;
      font-size: large;
    }

    button {
      margin: 0 auto;
      padding: 10px;
      width: 30%;
      transition: 0.3s ease;
      border: 1px solid rgb(184, 183, 183);
      border-radius: 50px;
      font-size: large;
      &:hover,
      &:focus {
        color: aliceblue;
        background-color: rgb(20, 101, 39);
        cursor: pointer;
      }
    }

    .actions {
      width: 70%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      button {
        margin: 0;
        width: 48%;

        &:nth-child(2):hover,
        &:nth-child(2):focus {
          background-color: orange;
        }
      }
    }
  }

  .tableWrapper {
    overflow: hidden;
    max-width: 400px;
    margin: 20px auto;
    border: 2px solid rgb(181, 211, 175);
    border-radius: 20px;

    table {
      border: 0;
      width: 100%;
      border-collapse: collapse;
      padding: 15px;

      thead {
        tr:nth-child(1) {
          background-color: rgb(127, 185, 230);
        }
      }

      tbody {
        tr:nth-child(2n) {
          background-color: rgb(255, 195, 168);
          text-align: center;
        }

        tr:nth-child(2n -1) {
          background-color: rgb(225, 75, 50);
          text-align: center;
        }
      }
    }
  }
}
