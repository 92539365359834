.header {
  box-sizing: border-box;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 10vh;
  padding: 0 10vw;
  z-index: 10;
  color: white;
  transition: 0.4s ease-out;
  background-color: black;

  img {
    max-height: 8vh;
  }

  a {
    color: inherit;
    text-decoration: none;
    text-transform: uppercase;
  }

  ul {
    display: flex;
    list-style: none;
    justify-content: space-between;
  }

  li {
    text-transform: uppercase;
    a {
      margin: 0.2rem;
      padding: 1rem 0.5rem;

      &:hover {
        background-color: rgba(128, 128, 128, 0.692);
      }
    }
  }

  input {
    display: none;
  }

  button {
    height: 100%;
    padding: 1rem;
    border-radius: 25px;
    cursor: pointer;
    color: wheat;
    background-color: orangered;
    transition: 0.5s;

    &:hover {
      scale: 1.1;
    }
  }
}
