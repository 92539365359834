.modalWrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.356);
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;

  .modalContainer {
    width: 500px;
    background-color: rgb(224, 209, 209);
    height: 500px;
    text-align: center;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .buttons {
      display: flex;
      gap: 20px;
      justify-content: center;
      margin: 1rem;

      button {
        padding: 0.5rem;
        cursor: pointer;
        background-color: whitesmoke;
        border: none;
        border-radius: 0.25rem;

        &:hover {
          background-color: orangered;
        }
      }
    }

    label,
    input {
      cursor: pointer;
      margin: 0 0.5rem;
    }
  }
}
