@use "../../../globalStyles" as *;

.hero {
  max-width: 1200px;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .logoWrapper {
    margin: 100px auto 0px;
    max-width: 50%;

    img {
      width: 100%;
    }
  }

  .aboutUsWrapper {
    max-width: 70%;
    margin: 10px auto 0px;
    text-align: center;
    font-size: x-large;

    h1 {
      color: rgb(151, 3, 3);
    }

    div {
      transition: 1s ease-in;
      transform: translateY(50%);
      opacity: 0;
    }

    p {
      color: whitesmoke;
    }
  }

  .aboutUsWrapper.aboutUsInView {
    div {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  .rentWrapper {
    display: grid;
    gap: 15px;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    text-align: center;
    max-width: 90%;
    margin: 50px auto 0px;
    text-align: center;
    font-size: x-large;

    .rentImgWrapper {
      transition: 1s ease-in;
      margin: 10px auto 0px;
      max-width: 100%;
      grid-column: 1/2;
      opacity: 0;
      transform: translateX(-50%);

      img {
        height: 100%;
        max-width: 80%;
        border-radius: 50px;
      }
    }

    .rentTextWrapper {
      transition: 1s ease-in;
      opacity: 0;
      transform: translateX(50%);
    }

    h1 {
      grid-column: 1/3;
      color: rgb(151, 3, 3);
    }

    p {
      color: whitesmoke;
      grid-column: 2/3;
    }
  }

  .reservationWrapper {
    grid-column: 1/3;
    opacity: 0;
    transition: 1s ease-in;
  }

  .rentWrapper.rentWrappersInView {
    .rentImgWrapper {
      opacity: 1;
      transform: translateX(0%);
    }

    .rentTextWrapper {
      opacity: 1;
      transform: translateX(0%);
    }

    .reservationWrapper {
      opacity: 1;
    }
  }
}

.mapWrapper {
  width: 90%;
  max-width: 900px;
  height: 400px;
  margin: 0 auto 20px;
  border-radius: 5px;
  overflow: hidden;
  transition: 0.5s ease-in;
  opacity: 0;
}

.mapWrapper.mapWrapperInWiew {
  opacity: 1;
}

.icons {
  display: flex;
  gap: 4rem;
  justify-content: center;
  margin-bottom: 1rem;
  img {
    border-radius: 25%;
  }

  img:hover {
    cursor: pointer;
    transition: 0.2s ease-in;
    scale: 1.1;
    -webkit-box-shadow: 0px 0px 53px -3px rgba(255, 255, 255, 0.8);
    -moz-box-shadow: 0px 0px 53px -3px rgba(255, 255, 255, 0.8);
    box-shadow: 0px 0px 53px -3px rgba(255, 255, 255, 0.8);
  }
}

@media (max-width: $mobile-width) {
  .hero {
    .logoWrapper {
      max-width: 80%;
      margin-top: 10vh;

      img {
        display: none;
      }
    }

    .aboutUsWrapper {
      margin-top: 1rem;
      max-width: 90%;
    }

    .rentWrapper {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      gap: 0;

      .rentImgWrapper {
        margin: 0 0 1rem 0;

        img {
          max-height: 385px;
          max-width: 100%;
        }
      }
    }

    p {
      font-size: large;
    }
  }
}
