.layout {
  display: grid;
}

.contentWrapper {
  max-width: 1200px;
  margin: 10vh auto;
  width: 100%;
}

.outletWrapper {
  display: block;
  margin-left: 250px;
}
