@use "../../../../globalStyles" as *;

.extrasForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;

  input {
    display: none;
  }

  div {
    border: 2px solid orangered;
    border-radius: 1rem;
    transition: 0.2s ease-in;
  }

  label {
    padding: 5px;
    line-height: 40px;

    &:hover {
      cursor: pointer;
    }
  }
}

.listOfAllOfferedExtras {
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: center;

  h2 {
    line-height: 4rem;
  }
}

.selected {
  -webkit-box-shadow: 0px 0px 25px -9px rgb(255, 0, 0);
  -moz-box-shadow: 0px 0px 25px -9px rgb(255, 0, 0);
  box-shadow: 0px 0px 25px -9px rgb(255, 0, 0);
  background-color: orangered;
  label {
    color: rgba(240, 248, 255, 0.846);
  }
}

@media (max-width: $mobile-width) {
  .listOfAllOfferedExtras {
    margin-top: 0.2rem;
  }
}
