.shisha {
  width: 300px;
  background: rgb(244, 11, 11);
  background: radial-gradient(
    circle,
    rgba(244, 11, 11, 1) 39%,
    rgba(227, 123, 10, 1) 100%
  );
  -webkit-box-shadow: 0px -8px 20px -3px rgba(255, 255, 255, 0.4);
  -moz-box-shadow: 0px -8px 20px -3px rgba(255, 255, 255, 0.4);
  box-shadow: 0px -8px 20px -3px rgba(255, 255, 255, 0.4);
  color: black;
  line-height: 1.5em;
  margin: 10px;
  text-align: center;
  border-radius: 25px;
  overflow: hidden;
  transition: all ease-in 0.15s;
  &:hover {
    cursor: pointer;
    scale: 1.1;
    transform: translate(0, -10px);
    -webkit-box-shadow: 0px 0px 53px -3px rgba(255, 255, 255, 1);
    -moz-box-shadow: 0px 0px 53px -3px rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 53px -3px rgba(255, 255, 255, 1);
  }
}

.imgWrapper {
  width: 100%;
  height: 250px;
  margin-bottom: 10px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-bottom-left-radius: 80px;
    border-bottom-right-radius: 80px;
  }
}
