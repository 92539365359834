.galeryWrapper {
  width: 85%;
  margin: 0px auto 0px;
  text-align: center;
  font-size: x-large;

  h1 {
    color: rgb(151, 3, 3);
    line-height: 8rem;
  }
}
