.tobaccosForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 10px;

  input {
    display: none;
  }

  label {
    padding: 5px;
    line-height: 40px;

    &:hover {
      cursor: pointer;
    }
  }
}

.listOfAllOfferedTobaccos {
  padding-bottom: 2rem;
  border-bottom: 2px dashed orangered;
  grid-column: 1/3;
  margin-bottom: 5px;
  text-align: center;

  .tobaccoTitle {
    color: red;
  }

  .inputError {
    border: 2px solid red !important;
  }

  .error {
    margin: 0.5rem auto;
    font-size: 1rem;
    color: red;
    border: 2px dotted red;
    padding: 0 1rem;
  }

  p {
    display: inline;
    color: red;
    font-size: x-large;
  }
}

.selected {
  -webkit-box-shadow: 0px 0px 25px -9px rgb(255, 255, 255);
  -moz-box-shadow: 0px 0px 25px -9px rgb(255, 255, 255);
  box-shadow: 0px 0px 25px -9px rgb(255, 255, 255);
  background-color: orangered;
  color: rgba(240, 248, 255, 0.846);
}

.classicTobaccosDiv {
  div > div {
    border: 2px solid orangered;
    border-radius: 1rem;
    transition: 0.2s ease-in;
  }
  width: 45%;
}

.classicTobaccos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.molassesDiv {
  div > div {
    border: 2px solid orangered;
    border-radius: 1rem;
    transition: 0.2s ease-in;
  }
  width: 45%;
  display: flex;
  flex-direction: column;

  p {
    margin-bottom: 0.3rem;
    color: orangered;
    font-size: small;
  }
}

.molasses {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}
