@use "../../../../globalStyles" as *;

.header {
  box-sizing: border-box;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 10%;
  padding: 0 10vw;
  z-index: 10;
  color: white;
  transition: 0.4s ease-out;
  background-color: black;
  top: 0;

  img {
    max-width: 100%;
    max-height: 8vh;
    cursor: pointer;
  }

  a {
    color: inherit;
    text-decoration: none;
    text-transform: uppercase;
  }

  label {
    position: relative;
    cursor: pointer;
    padding: 20px 10px;
    z-index: 10;
    display: none;

    span {
      display: block;
      position: relative;
      background-color: wheat;
      height: 2px;
      width: 20px;
      border-radius: 4px;

      &::before,
      &::after {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        border-radius: 4px;
        background-color: wheat;
        transition: 0.8s;
      }

      &::before {
        transform: translateY(-5px);
      }

      &::after {
        transform: translateY(5px);
      }
    }
  }

  ul {
    display: flex;
    list-style: none;
    justify-content: space-between;
  }

  li {
    text-transform: uppercase;
    cursor: pointer;

    a {
      margin: 0.2rem;
      padding: 1rem 0.5rem;

      &:hover {
        background-color: rgba(128, 128, 128, 0.692);
      }
    }
  }

  input {
    display: none;
  }

  .portal {
    z-index: 1000;
    position: absolute;
    right: 1rem;
    top: 0;
    color: rgb(80, 3, 3);
  }
}

@media (max-width: $mobile-width) {
  .header {
    padding: 0;
    display: grid;
    grid-template-columns: 80% 20%;

    .portal {
      right: 0;
    }

    .linkWrapper {
      padding-left: 1rem;
    }

    ul {
      display: none;
    }

    &.open {
      padding: 0;
      display: grid;
      grid-template-columns: 80% 20%;

      ul {
        grid-column: 1/3;
        max-height: 90vh;
        display: flex;
        flex-direction: column;
        background-color: black;
        text-align: center;
        justify-content: space-evenly;
        height: 100vh;
        width: 100vw;
        overflow: auto;
      }

      span {
        background-color: transparent;

        &::before {
          transform: rotate(135deg);
        }

        &::after {
          transform: rotate(45deg);
        }
      }
    }

    .linkWrapper {
      min-height: 10vh;
      background-color: black;
    }

    label {
      padding: 0;
      width: 100%;
      height: 100%;
      background-color: black;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
