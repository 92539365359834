@use "../../../../../globalStyles" as *;

.selectedShishaPreview {
  display: flex;
  padding: 20px;
  text-align: center;
  border-bottom: 2px dashed rgb(230, 102, 10);
}

.imgWrapper {
  width: 40%;

  img {
    width: 100%;
    max-height: 350px;
    object-fit: cover;
    border-radius: 80px;
  }
}

.shishaDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;

  h1,
  h2 {
    color: orangered;
  }

  p {
    color: wheat;
    margin: 3% 10% 3%;
  }
}

@media (max-width: $mobile-width) {
  .selectedShishaPreview {
    flex-direction: column;
    align-items: center;
  }

  .imgWrapper {
    width: 100%;
    max-width: 300px;
  }
}
