@import url("https://fonts.googleapis.com/css2?family=Kalam&display=swap");

html {
  overflow-y: scroll;
}

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: black;
  background-image: url("../src/images/background-smoke-small.jpg");
  background-size: cover;
  background-attachment: fixed;
  font-size: large;
  font-family: "Kalam", sans-serif;
}
