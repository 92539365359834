@use "../../../globalStyles" as *;

.contactWrapper {
  font-size: x-large;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  color: whitesmoke;

  h1 {
    margin-top: 1rem;
    color: rgb(151, 3, 3);
    grid-column: 1/4;
  }

  h2 {
    color: orangered;
  }

  h4 {
    margin-bottom: 0.3rem;
    border-bottom: 2px dashed orangered;
  }

  .address {
    grid-column: 1/2;
  }
  .hours {
    grid-column: 2/3;
  }
  .telNumber {
    grid-column: 3/4;
  }
}

@media (max-width: $mobile-width) {
  .contactWrapper {
    .address,
    .hours,
    .telNumber {
      grid-column: 1/4;
    }
  }
}
